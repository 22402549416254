import React from "react";
import classNames from "classnames";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { Link } from "gatsby";
import styled from "styled-components";

interface PostNavigationLinkProps {
  direction: string;
  link: any;
}

const StylesPostNavigationLink = styled.div`
  display: flex;
  flex-direction: column;
`;

const PostNavigationType = styled.div`
  letter-spacing: 2px;
  color: rgb(202, 202, 202);
  display: inline-block;
  font-size: 14px;
  padding-bottom: 0.2rem;
  text-transform: uppercase;
  text-align: ${props => (props?.isNext ? "right" : "left")};
`;

const PostNavigationTitle = styled.h4`
  margin: 10px 0;
  a {
    display: block;
    clear: both;
    float: left;
    max-width: 300px;
    width: 100%;
    margin-bottom: 1em;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    color: #333;
    transition: all 0.3s ease-in-out;
  }
`;

const PostNavigationLink: React.FC<PostNavigationLinkProps> = ({
  direction = "prev",
  link,
}) => {
  if (!link) return <div />;

  return (
    <StylesPostNavigationLink>
      <PostNavigationType isNext={direction === "next"}>
        {direction === "prev" ? "Previous post" : "Next Post"}
      </PostNavigationType>
      <PostNavigationTitle>
        <Link to={`/blog${link?.node?.fields?.slug}`}>
          {direction === "prev" ? <FiArrowLeft /> : <FiArrowRight />}{" "}
          &nbsp; {link?.node?.frontmatter?.title}
        </Link>
      </PostNavigationTitle>
    </StylesPostNavigationLink>
  );
};

export default PostNavigationLink;
