import React from "react";
import styled from "styled-components";
import PostNavigationLink from "../PostNavigationLink";

interface PostNavigationProps {
  prev: any;
  next: any;
}

const StyledPostNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
`;

const PostNavigation: React.FC<PostNavigationProps> = ({
  prev,
  next,
}) => {
  return (
    <StyledPostNavigation>
      <PostNavigationLink direction="prev" link={prev} />
      <PostNavigationLink direction="next" link={next} />
    </StyledPostNavigation>
  );
};

export default PostNavigation;
